
import QRCode from "qrcode";
import { useEffect, useRef } from "react";

function QRCodeGen({text}){

    const canvaRef = useRef()

    useEffect(() => {
        QRCode.toCanvas(canvaRef.current,text)


    }, [text]);


    return(
        <div>
            <canvas ref={canvaRef} id="canvas"></canvas>
        </div>
    )
}

export default QRCodeGen