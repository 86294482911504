
import styles from './FuncPosts.module.css'

function FuncPosts({ title, subTitle, lines, icon }) {
    return (
        <div className={styles.mainDiv}>

            <div className={styles.titleDiv}>
                <h4>{title}</h4>
                <div className={styles.retangle}>
                    <img src={icon} alt='icone de funções' />
                </div>
            </div>
            <h5>{subTitle}</h5>
        </div>
    )
}

export default FuncPosts