import { useEffect, useState } from "react"
import ButtonMain from "../elements/ButtonMain"
import Footer from "../elements/Footer"
import NavBar from "../elements/NavBar"
import styles from "./ExcluirConta.module.css"
import postbox from "../images/post-box.png"

import FailMsg from "../elements/FailMsg";
import getGlobalVariable from "../elements/MainVariables"
import { Link } from "react-router-dom"

function ExcluirConta() {

    const [page, setPage] = useState(1)
    const [unlockDelete, setUnlockDelete] = useState(false)

    const [userData, SetUserData] = useState()
    const [displaySucess, setDisplaySucess] = useState(false)
    const [displayFail, setDisplayFail] = useState(false)

    const [msgFail, setMsgFail] = useState()



    const excluirClick = () => {

        window.scrollTo(0, 0)
        if (page === 1) {
            setUnlockDelete(false)
            if (userData.email === undefined) {
                setMsgFail("Preencha o campo de e-mail")
                setDisplayFail(true)
                setTimeout(() => { setDisplayFail(false) }, "3000")

            } else {
                postEmail()
                //
 

            }
        }else if(page === 2) {
            nextPage()
        }
    }

    const nextPage = () => {
        setPage(page + 1)
    }

    const postEmail = () => {

        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let raw = JSON.stringify({
            "email": userData.email,
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };


        fetch(`${getGlobalVariable().mainUrl}/delete_account`, requestOptions)
            .then(response => {
                if(response.status === 200){
                    if(page === 1){
                        nextPage()

                    }else{

                        setDisplaySucess(true)
                        setTimeout(() => { setDisplaySucess(false) }, "3000")
                    }

                }else if(response.status === 404){
                    setMsgFail("Email não encontrado.")
                    setDisplayFail(true)
                    setTimeout(() => { setDisplayFail(false) }, "3000")

                }else{
                    setMsgFail("Houve um erro... Tente novamente mais tarde")
                    setDisplayFail(true)
                    setTimeout(() => { setDisplayFail(false) }, "3000")

                }
            }

        )


    }



    const handleChangeValues = (value) => {

        SetUserData((prevValue) => ({
            ...prevValue,
            [value.target.name]: value.target.value,

        }));


    };

    useEffect(() => {

        const isValidEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if(userData !==undefined){
            if (page === 1 && userData.email !== undefined) {
                if ((userData.email).match(isValidEmail)) {
                    setUnlockDelete(true)
                } else {
                    setUnlockDelete(false)
                }
            }
        }


    }, [userData, page]);

    return (
        <div className={styles.mainDiv} id="mainDiv">
            <NavBar />
            {displayFail && <FailMsg msg={msgFail} sucess={false} />}
            {displaySucess && <FailMsg msg={"E-mail enviado com sucesso!"} sucess={true}/>}
            {page === 1 &&
                <>
                    <div className={styles.mainSubDiv}>
                        <h2>Excluir conta</h2>
                        <h4>Fique com a gente! Caso esteja experienciando algum problema,<mark className={styles.highlightedText}> entre em contato</mark> conosco que tentaremos resolver. Se realmente quiser excluir sua conta, insira seu e-mail abaixo.</h4>
                    </div>
                    <div className={styles.emailArea}>
                        <input className={styles.textInput} name="email" placeholder="E-mail" onChange={handleChangeValues}></input>
                        <h5>Utilize o e-mail com o qual a conta foi cadastrada</h5>
                        <div className={styles.btnExcluir}>
                            {unlockDelete ? <ButtonMain onclickfunc={excluirClick} txt={"Excluir Conta"}></ButtonMain> : <button className={styles.disableBtn}>Excluir Conta</button>}
                        </div>
                    </div>
                    <div className={styles.footerFiller} style={{ height: "20rem" }}></div>
                </>
            }
            {page === 2 && userData.email !== undefined &&
                <>
                    <div className={styles.mainSubDiv}>
                        <img src={postbox} alt="Confirmação de exclusão enviada"></img>
                        <h2>Confirmação de exclusão enviada</h2>
                        <h4>Enviamos um link de confirmação para o e-mail <mark className={styles.highlightedText}>{userData.email}</mark>. Caso não queira mais excluir sua conta, é só ignorar o e-mail. O link expira após x horas.</h4>
                        <h4> Não recebeu a confirmação de exclusão? <mark className={styles.highlightedText}><button className={styles.ReSendButton} onClick={postEmail}>Reenviar link</button></mark> </h4>
                        <Link to={'/'}><ButtonMain txt={"Voltar ao inicio"}></ButtonMain></Link>
                    </div>
                    <div className={styles.footerFiller} style={{ height: "20rem" }}></div>
                </>
            }

            <Footer heightFooter={'15.813rem'} />
        </div>
    )
}

export default ExcluirConta