import { Link } from 'react-router-dom'
import styles from './NavBar.module.css'
import windowSize from '../elements/WindowsWatch'
import { useState, useEffect } from 'react'

import athleticLogoPurple from '../images/athletic-gear-logo-purple.png'

function NavBar({showFaleconosco}){


    const [menuOpen, setMenuOpen] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [mobile, setMobile] = useState()
    

    const clickMenu = async() =>{

        if (width > 628) {
            setMenuOpen(!menuOpen)
            setMobile(false)
        }
        else {
            setClicked(!clicked)
            setMobile(true)         
        }
    }

    
    const { width, height } = windowSize();
    useEffect(() => {
        if (width > 628) {
            setMobile(false)

        }
        else {
            setMobile(true)        

        }
    }, [width, height])




    return(
        <div className={styles.mainDivContainer}>
            <div className={styles.mainDiv} > 
                <div className={styles.subDivLeft}>
                    {showFaleconosco &&
                        <button className={styles.menuNavBar} onClick={clickMenu}></button>
                    }
                    <Link to={'/'}><img className={styles.athleticLogo} src={athleticLogoPurple} alt='Athletic Logo'/></Link>
                </div>
                {showFaleconosco &&
                <div className={styles.subDivRight}>  
                    <a className={styles.link} href='#fale_conosco'>Fale conosco</a>  
                </div>
                }
        </div>
        {menuOpen === true && mobile === false &&
            <div className={styles.popUpMenu}>
                <a className={styles.popUpLink} onClick={clickMenu} href='#sobre_nos'>Sobre nós</a>
                <a className={styles.popUpLink} onClick={clickMenu} href='#funcionalidades'>Funcionalidades</a>
                <a className={styles.popUpLink} onClick={clickMenu} href='#depoimentos'>Depoimentos</a>
                <a className={styles.popUpLink} onClick={clickMenu} href='#fale_conosco'>Fale conosco</a>           

            </div>
        }
            {mobile === true &&
            <div className={styles.popUpMenuMobile}style={{marginLeft: !clicked ? '-395px' : '0px'}}> 
                <h4>Menu</h4>
                <a className={styles.popUpLinkMobile} onClick={clickMenu} href='#sobre_nos'>Sobre nós</a>
                <a className={styles.popUpLinkMobile} onClick={clickMenu} href='#funcionalidades'>Funcionalidades</a>
                <a className={styles.popUpLinkMobile} onClick={clickMenu} href='#lindepoimentosk03'>Depoimentos</a>
                <a className={styles.popUpLinkMobile} onClick={clickMenu} href='#fale_conosco'>Fale conosco</a>   
                <button className={styles.closeMenu} onClick={clickMenu} ></button>        
            </div>   
            }
            {mobile === true && clicked === true &&
                <div className={styles.dimmerArea} onClick={clickMenu} ></div>
            }
        </div>
    )
}

export default NavBar