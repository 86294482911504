import styles from './FailMsg.module.css'

function FailMsg({msg, sucess = false}){
    return(
        <>
        {sucess && 
        <div className={styles.sucessMsg}>
            <h3>{msg}</h3>
        </div>
        }
        {!sucess &&
        <div className={styles.failMsg}>
             <h3>{msg}</h3>
         </div>   
        }
        </>

    )
}

export default FailMsg