
import styles from './NotFound.module.css'

import Footer from "../elements/Footer";
import NavBar from "../elements/NavBar";
import { Link } from 'react-router-dom';


function NotFound(){


    return(
        <div  className={styles.mainDiv}>
            <NavBar/>
            <div className={styles.subDiv}>
                <img src='images/not-found.png' alt='pagina não encontrada'/>
                <h3>Página não encontrada</h3>
                <Link to={"/"} className={styles.nextBtn}>Voltar para o início</Link>
            </div>
            <Footer heightFooter={'15.813rem'}/>
        </div>
    )
}

export default NotFound