import { useEffect, useState } from "react"
import ButtonMain from "../elements/ButtonMain"
import Footer from "../elements/Footer"
import NavBar from "../elements/NavBar"
import styles from "./ExcluirConta.module.css"
import infoIcon from "../images/info-icon.png"
import deleteImg from "../images/delete-final-image.png"
import notFoundimg from "../images/not-found.png"
import { Link } from "react-router-dom"
import { useLocation } from "react-router-dom"
import getGlobalVariable from "../elements/MainVariables"
import FailMsg from "../elements/FailMsg"

function ExcluirConta() {


    const [page, setPage] = useState(0)
    const [showtextArea, setShowtextarea] = useState(false)
    const [unlockDelete, setUnlockDelete] = useState(false)
    const [displayFail, setDisplayFail] = useState(false)

    // retirar o marcador de comentario quando a API estiver funcionando
    const uuid = useLocation().pathname.split("/")[2]
    const preUuidUrl = `${getGlobalVariable().mainUrl}/delete_account/`
    const [responseData, setResponseData] = useState()
    
    
    /*
    // excluir quando a Api estiver funcionado
    const [responseData] = useState({
        "name": "AG Team 🤪",
        "email": "ag_team1@hi2.in",
        "account_type": "team",
        "pending_payment": true,
      })

    */


    
    const [userData, SetUserData] = useState(
        {
            "cb1": false,
            "cb2": false,
            "cb3": false,
            "cb4": false,
            "cb5": false,
            "cb6": false,
            "cb7": false,
            "cb8": false,
        })

    const excluirClick = () => {

        window.scrollTo(0, 0)
         if (page === 1) {
            setUnlockDelete(false)
            if (userData.cb1 === false && userData.cb2 === false && userData.cb3 === false && userData.cb4 === false) {

            } else {
                if (userData.cb4 === false) {
                    userData.outro = ""
                }
                nextPage()
            }
        } else if (page === 2) {

            if (userData.cb5 === false || userData.cb6 === false || userData.cb7 === false || userData.cb8 === false) {

            } else {

                deleteAccount()

            }
        }
    }

    const nextPage = () => {
        setPage(page + 1)
    }

    
    // retirar o marcador de comentario quando a API estiver funcionando

    
    const getData = () => {

        fetch(`${preUuidUrl}${uuid}`)
            .then(response => response.json())

            .then(response => {if(response.error === undefined){
                setResponseData(response)
                setPage(1)

            }else{
                setPage(4)

            }})


    }
    


    const checkClick = (e) => {

        if (e.target.name === "cb4") {
            setShowtextarea(!showtextArea)
        }
    }

    
    const deleteAccount = () => {

        let reasons = ""
        if(userData.cb1 === true){
            reasons += "cb1 = true"
 
        }
        if(userData.cb2 === true){
            if(reasons.slice(-1) !== ","){
                reasons += ","
            }
            reasons += "cb2 = true"
        }
        if(userData.cb3=== true){
            if(reasons.slice(-1) !== ","){
                reasons += ","
            }
            reasons += "cb3 = true"
        }
        if(userData.cb4 === true){
            if(reasons.slice(-1) !== ","){
                reasons += ","
            }
            reasons += "cb4 = true, outro = "+userData.outro

        }
        
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let raw = JSON.stringify({
            "reason": reasons,
        });


        let requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
          
          fetch(`${preUuidUrl}${uuid}`, requestOptions)
            .then(response => {
                if(response === undefined){
                    
                    setDisplayFail(true)
                    setTimeout(() => { setDisplayFail(false) }, "3000")
                }else{
                    if(response.status === 200){
                        nextPage()
                    }else{
                        setDisplayFail(true)
                        setTimeout(() => { setDisplayFail(false) }, "3000")
                    }
                    
                }
            })

    }


    const handleChangeValues = (value) => {


        SetUserData((prevValue) => ({
            ...prevValue,
            [value.target.name]: value.target.value,

        }));


    };


    const handleChangeCheck = (value) => {

        SetUserData((prevValue) => ({
            ...prevValue,
            [value.target.name]: value.target.checked,

        }));

    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        // retirar o marcador de comentario quando a API estiver funcionando



        if (page === 1) {
            if (userData.cb1 === true || userData.cb2 === true || userData.cb3 === true || userData.cb4 === true) {
                setUnlockDelete(true)
            } else {
                setUnlockDelete(false)
            }
        } else if (page === 2){
            if (userData.cb5 === true && userData.cb6 === true && userData.cb7 === true && userData.cb8 === true) {
                setUnlockDelete(true)
            } else {
                setUnlockDelete(false)
            }

        }


    }, [userData, page]);

    return (
        <div className={styles.mainDiv} id="mainDiv">
            <NavBar />
            {displayFail && <FailMsg msg={"Houve um erro... Tente novamente mais tarde"} sucess={false} />}
            {page === 1 && responseData !== undefined &&
                <>
                    <div className={styles.warningDiv}>
                        <h4>Você está excluindo a conta <mark className={styles.highlightedText}>{responseData.email}</mark></h4>
                        <h4>Está experienciando algum problema? <mark className={styles.highlightedText}>Entre em contato com o suporte</mark></h4>
                    </div>
                    <div className={styles.mainSubDiv} style={{ marginTop: "0rem" }}>
                        <h2>Por que você está excluindo sua conta?</h2>
                        <h4>Nos ajude com um feedback para melhorarmos!</h4>
                    </div>
                    <div className={styles.checkArea}>
                        <div className={styles.checkAreaSub}>
                            <div className={styles.checkboxArea}>
                                <input type="checkbox" id="cb1" onClick={handleChangeCheck} name="cb1"></input>
                                <span className={styles.checkmark}></span>
                                <label htmlFor="cb1">O app não é útil para mim</label>
                            </div>
                            <div className={styles.checkboxArea}>
                                <input type="checkbox" id="cb2" onClick={handleChangeCheck} name="cb2"></input>
                                <span className={styles.checkmark}></span>
                                <label htmlFor="cb2">Não encontrei a funcionalidade que precisava</label>
                            </div>
                            <div className={styles.checkboxArea}>
                                <input type="checkbox" id="cb3" onClick={handleChangeCheck} name="cb3"></input>
                                <span className={styles.checkmark}></span>
                                <label htmlFor="cb3">Tive problemas técnicos</label>
                            </div>
                            <div className={styles.checkboxArea}>
                                <input type="checkbox" id="cb4" onChange={handleChangeCheck} onClick={checkClick} name="cb4"></input>
                                <span className={styles.checkmark}></span>
                                <label htmlFor="cb4">Outro</label>
                            </div>
                        </div>
                        {showtextArea ? <textarea className={styles.checkTextArea} name="outro" onChange={handleChangeValues} placeholder="Descreva seu motivo (opcional)"></textarea> : <div className={styles.textPlaceholder}></div>}
                        <div className={styles.btnExcluir}>
                            <button className={styles.prevBtn} onClick={nextPage}>Pular</button>
                            {unlockDelete ? <ButtonMain onclickfunc={excluirClick} txt={"Próximo"}></ButtonMain> : <button className={styles.disableBtn}>Próximo</button>}
                        </div>

                    </div>
                    <div className={styles.footerFiller} style={{ height: "20rem" }}></div>
                </>
            }
            {page === 2 && responseData !== undefined &&
                <>
                    <div className={styles.warningDiv}>
                    <h4>Você está excluindo a conta <mark className={styles.highlightedText}>{responseData.email}</mark></h4>
                        <h4>Está experienciando algum problema? <mark className={styles.highlightedText}>Entre em contato com o suporte</mark></h4>
                    </div>
                    <div className={styles.mainSubDiv} style={{ marginTop: "0rem" }}>
                        <h2>Excluir conta</h2>
                    </div>

                    <div className={styles.checkArea}>
                        {responseData.pending_payment === true &&
                            <div className={styles.warningInfoDiv}>
                                <img src={infoIcon} alt="informação"></img>
                                <h4>Você possui cobranças em aberto. Caso exclua sua conta mesmo assim, os valores ainda devem ser pagos ao time devido por meio de terceiros. A exclusão da conta não anula pagamentos em aberto. </h4>
                            </div>
                        }
                        {responseData.account_type === "team" &&
                            <div className={styles.warningInfoDiv}>
                                <img src={infoIcon} alt="informação"></img>
                                <h4>Ao excluir sua conta, as contas de todos os atletas cadastrados no seu time serão automaticamente desativadas. Eles não poderão mais fazer login na plataforma. </h4>
                            </div>
                        }
                        <h3>Ao excluir sua conta, você concorda com os seguintes termos:</h3>
                        <div className={styles.checkAreaSubTwo}>
                            <div className={styles.checkboxArea}>
                                <input type="checkbox" id="cb5" onClick={handleChangeCheck} name="cb5"></input>
                                <span className={styles.checkmark}></span>
                                <label htmlFor="cb5">Reconheço que ainda devo pagar cobranças em aberto ao time que participo</label>
                            </div>
                            <div className={styles.checkboxArea}>
                                <input type="checkbox" id="cb6" onClick={handleChangeCheck} name="cb6"></input>
                                <span className={styles.checkmark}></span>
                                <label htmlFor="cb6">Concordo com os termos de exclusão de conta da Athletic Gear</label>
                            </div>
                            <div className={styles.checkboxAreaTwo}>
                                <input type="checkbox" id="cb7" onClick={handleChangeCheck} name="cb7"></input>
                                <span className={styles.checkmarkTwo}></span>
                                <label htmlFor="cb7">Entendo que esse processo não pode ser revertido e que perderei todos os dados associados a essa conta</label>
                            </div>
                            <div className={styles.checkboxArea}>
                                <input type="checkbox" id="cb8" onClick={handleChangeCheck} name="cb8"></input>
                                <span className={styles.checkmark}></span>
                                <label htmlFor="cb8">Concordo que meu time seja notificado sobre a exclusão da minha conta</label>
                            </div>
                        </div>
                        <div className={styles.btnExcluir}>
                            <Link to={'/'}><button className={styles.prevBtn}>Cancelar exclusão</button></Link>
                            {unlockDelete ? <ButtonMain onclickfunc={excluirClick} txt={"Excluir conta"}></ButtonMain> : <button className={styles.disableBtn}>Excluir conta</button>}
                        </div>

                    </div>
                    <div className={styles.footerFiller} style={{ height: "20rem" }}></div>
                </>
            }
            {page === 3 && responseData !== undefined &&
                <>
                    <div className={styles.mainSubDiv}>
                        <img className={styles.deleteImg} src={deleteImg} alt="Confirmação de exclusão enviada"></img>
                        <h2>Conta excluída</h2>
                        <h4>Esperamos revê-lo novamente!</h4>
                        <Link className={styles.titleDiv} href="#mainDiv" id='link00' to={'/'}><ButtonMain onclickfunc={excluirClick} txt={"Voltar ao inicio"}></ButtonMain></Link>
                    </div>
                    <div className={styles.footerFiller} style={{ height: "20rem" }}></div>
                </>
            }
            {page === 4 &&
                <div className={styles.notFound}>
                    <NavBar/>
                    <div className={styles.notFoundSubDiv}>
                        <img src={notFoundimg} alt='pagina não encontrada'/>
                        <h3>Página não encontrada</h3>
                        <Link to={"/"} className={styles.nextBtn}>Voltar para o início</Link>
                    </div>
                    <Footer heightFooter={'15.813rem'}/>
                </div>
            }
            <Footer heightFooter={'15.813rem'} />
        </div>
    )
}

export default ExcluirConta