import React from 'react';
import styles from './Home.module.css'
import { Link } from 'react-router-dom';
import FuncPosts from '../elements/FuncPosts';
import { useState, useRef, useEffect } from 'react';
import InputMask from 'react-input-mask';
import windowSize from '../elements/WindowsWatch'
import Footer from '../elements/Footer';
import NavBar from '../elements/NavBar';
import getGlobalVariable from "../elements/MainVariables"


function Home() {


    let title = "A plataforma de gestão para equipes inteligentes"
    let subtitle = "Faça o controle da sua equipe de forma fácil, organizada e automatizada."

    const partnersCarousel = useRef(null)
    const cardsCarousel = useRef(null)
    const { width } = windowSize();
    const [athleticlmg, setAthleticlmg] = useState()
    const [add01Img, setadd01Img] = useState()
    const [functionImg, setFunctionImg] = useState()
    const [backgroundPhoneImg, setBackgroundPhoneImg] = useState()


    const [displaySucess, setDisplaySucess] = useState(false)
    const [displayFail, setDisplayFail] = useState(false)


    const [formValues, setFormValues] = useState({
        name: "",
        team: "",
        role: "",
        email: "",
        phone: "",
        message: "",
    });

    const cards = [
        { id: "1", author: "Maria Gabriela", icon: "images/carousel-05.png", subtitle: "Phoenis Football SP", text: "“A Athletic está ajudando o Phoenix muito, facilita os pagamentos e cobranças avulsas, consigo ajustar tudo pelo aplicativo super pratico!”" },
        { id: "2", author: "Ana Flávia", icon: "images/carousel-02.png", subtitle: "Golden Lions", text: "“O aplicativo foi um divisor de águas para nós atletas. Eu faço tudo por la! Consigo ver a confirmação dos treinos, o horário e o local.”" },
        { id: "3", author: "Marcela", icon: "images/carousel-03.png", subtitle: "Xtremers Cheerleading", text: "“O aplicativo athletic gear é uma ferramenta que vem nos auxiliando muito no controle financeiro do nosso time!”" },
        { id: "4", author: "Kayo", icon: "images/carousel-04.png", subtitle: "uninvolei", text: "“Nossa empresa vem crescendo e após o fechamento da parceria com a Athletic Gear, nossos problemas acabaram.”" },
    ]

    const partners = [
        { id: "1", name: "Unin Vôlei", type: "Vôlei", icon: "images/partners/01.png" },
        { id: "2", name: "Locomotives", type: "Futebol Americano", icon: "images/partners/02.png" },
        { id: "3", name: "SMC Futsal", type: "Escolinhas", icon: "images/partners/03.png" },
        { id: "4", name: "A.A.R.M.", type: "Rugby", icon: "images/partners/04.png" },
        { id: "7", name: "Life Voleibol", type: "Vôlei", icon: "images/partners/07.png" },
        { id: "8", name: "AFMT Futsal", type: "Futsal", icon: "images/partners/08.png" },
        { id: "10", name: "Espectros", type: "Futebol Americano", icon: "images/partners/10.png" },
        { id: "11", name: "Rio Preto Weillers", type: "Futebol Americano", icon: "images/partners/11.png" },
        { id: "12", name: "Adustina Clube", type: "Rugby", icon: "images/partners/12.png" },    
        { id: "13", name: "Urutu Clube", type: "Rugby", icon: "images/partners/13.png" },
        { id: "14", name: "Tigres All Star", type: "Cheerleading", icon: "images/partners/14.png" },

    ]

    function PostForm() {
        let myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        let raw = JSON.stringify({
            "name": formValues.name,
            "team": formValues.team,
            "role": formValues.role,
            "email": formValues.email,
            "phone": formValues.phone,
            "message": formValues.message
        });

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(`${getGlobalVariable().mainUrl}/web/lp_message`, requestOptions)
            .then(response => response.text())



    }

    const handleChangeValues = (value) => {
        setFormValues((prevValue) => ({
            ...prevValue,
            [value.target.name]: value.target.value,

        }));


    };

    const handleOnClick = () => {
        if (formValues.email === '' || formValues.name === '' || formValues.phone === '' || formValues.role === '' || formValues.role === '' || formValues.message === '') {
            formFail()

        } else {
            formSucess()
        }

    }


    const formFail = () => {
        setDisplayFail(true)
        setTimeout(() => { setDisplayFail(false) }, "1500")
    }

    const formSucess = () => {
        if (displaySucess === false) {
            setDisplaySucess(true)
            PostForm()
            setTimeout(() => {
                setDisplaySucess(false)
                window.location.reload();
            }, "3000")
        }


    }

    const partnersCarouselClickRight = (e) => {
        e.preventDefault();
        partnersCarousel.current.scrollLeft += partnersCarousel.current.offsetWidth;
    }

    const partnersCarouselClickLeft = (e) => {
        e.preventDefault();
        partnersCarousel.current.scrollLeft -= partnersCarousel.current.offsetWidth;
    }


    const cardsCarouselClickRight = (e) => {
        e.preventDefault();
        cardsCarousel.current.scrollLeft += cardsCarousel.current.offsetWidth;

    }

    const cardsCarouselClickLeft = (e) => {
        e.preventDefault();
        cardsCarousel.current.scrollLeft -= cardsCarousel.current.offsetWidth;

    }

    useEffect(() => {
        
        if (width > 1032){
            setFunctionImg("images/functions-01.png")
            setBackgroundPhoneImg("images/background-landing-phone.png")
            setAthleticlmg("images/athletes-01.png")
            setadd01Img("images/add-01.png")
        }else if (width > 757) {
            setFunctionImg("images/functions-01-768.png")
            setBackgroundPhoneImg("images/background-landing-phone.png")
            setAthleticlmg("images/athletes-01.png")
            setadd01Img("images/add-01.png")
        }else if (width > 628) {
            setFunctionImg("images/functions-01-576.png")
            setBackgroundPhoneImg("images/background-landing-phone-1032.png")
            setAthleticlmg("images/athletes-01.png")
            setadd01Img("images/add-01-758.png")
        }else {
            setBackgroundPhoneImg("images/background-landing-mobile-phone.png")
            setAthleticlmg("images/athletes-01-mobile.png")
            setadd01Img("images/add-01-mobile.png")
        }
    }, [width])



    return (
        <div className={styles.divMain}>
            <NavBar showFaleconosco={true}/>

            <div className={styles.subDivLanding} id='pagina_inicial' >
                <div className={styles.titleBackgroundBlur}></div>
            </div>
            <img className={styles.backgroundImgLanding} src={backgroundPhoneImg} alt='celular'/> 
            <div className={styles.subDivLandingTitle}>
                <h4>{title}</h4>
                <h5>{subtitle}</h5>
                <div className={styles.download}>
                    <p className={styles.downloadText}>Baixe agora</p>
                </div>
                <div>
                    <Link to={"https://play.google.com/store/apps/details?id=com.agsports.agsports_manager"}>
                        <img className={styles.googlePlay} src="images/google-play-download.png" alt='google play download' />
                    </Link>
                    <Link to={"https://apps.apple.com/br/app/athletic-gear-gestor/id1608434390"}>
                        <img className={styles.appStore} id='sobre_nos' src="images/app-store-download.png" alt='app store download' />
                    </Link>

                </div>
            </div>


            <div className={styles.descrptArea}>
                <div className={styles.descrptContainer}>
                    <img className={styles.atltDescrpt} src={athleticlmg} alt='atletas'></img>
                    <div className={styles.descrpt}>
                        <h4>Para todos os gestores</h4>
                        <h5>Seja você um treinador, gerente ou entusiasta, o SportsTeam Manager é a solução completa para todas as suas necessidades de gerenciamento de times de esportes.<b> Tenha o controle total sobre o desempenho do seu time.</b></h5>
                        <div className={styles.downloadDescriptArea}>
                            <p className={styles.descrptDownload} >Baixe agora</p>
                        </div>
                        <div>
                            <Link to={"https://play.google.com/store/apps/details?id=com.agsports.agsports_manager"}>
                                <img className={styles.googlePlayBlack} src="images/google-play-black.png" alt='google play download' />
                            </Link>
                            <Link id='funcionalidades' to={"https://apps.apple.com/br/app/athletic-gear-gestor/id1608434390"}>
                                <img className={styles.appStoreBlack} src="images/app-store-black.png" alt='app store download' />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>



            <div className={styles.functionsTitle} >
                <h4>Nossas funcionalidades</h4>
                <h5>Atuamos com a automatização da gestão da sua equipe.Receba, cobre e controle mensalidades, faça a gestão dos atletas ativos e inativos, controle a presença em treinos e mais</h5>
            </div>
            <div className={styles.functionsArea}>
                <div className={styles.functionContainer}>
                    <FuncPosts title="Gestão Esportiva" lines="1" subTitle="Tela principal com resumos da sua equipe, controle de inadimplência e previsibilidade de caixa, quantidade de atletas, próximos eventos e muito mais." icon="images/functions-icons-01.png" />
                    <FuncPosts title="Gestão de Atletas" lines="1" subTitle="Cadastre os dados, informações e observações de cada membro da equipe de forma fácil e organizada." icon="images/functions-icons-02.png" />
                    <FuncPosts title="Mensalidades automatizadas" lines="1" subTitle="Configure os valores, data de vencimento e demais características. Receberemos e cobraremos tudo de forma automatizada para você." icon="images/functions-icons-03.png" />
                    <FuncPosts title="Dashboard" lines="1" subTitle="Um dashboard com todos os indicadores da sua equipe te deixam ciente de tudo que acontece no seu dia a dia" icon="images/functions-icons-04.png" />
                    <FuncPosts title="Fluxo de caixa" lines="1" subTitle="Controle o que entra e sai no caixa do seu time e tenha a sua saúde financeira na palma da mão" icon="images/functions-icons-05.png" />
                    {width > 628 && <div className={styles.placeHolderFunc}></div>}
                </div>
            </div>
            {width > 628 && <img className={styles.funcImg} alt='celular' src={functionImg} />}


            <div className={styles.addContainer01}>
                <div className={styles.addBackgroundContainer}>
                    <img className={styles.addBackground} src={add01Img} alt='times e atletas cadastrados' />
                    <div className={styles.addTextContainer}>
                        <div className={styles.addTextSubContainer}>
                            <h4>+200</h4>
                            <h5>times cadastrados</h5>
                        </div>
                        <div className={styles.addTextSubContainer}>
                            <h4>+5.000</h4>
                            <h5>atletas cadastrados</h5>
                        </div>
                    </div>
                    <div className={styles.addDownloadAreaContainer}>
                        <div className={styles.addDownloadContainer}>
                            <p className={styles.addDownload}>Baixe agora</p>
                        </div>
                        <div className={styles.addDownloadIconContainer}>
                            <Link to={"https://play.google.com/store/apps/details?id=com.agsports.agsports_manager"}>
                                <img className={styles.googlePlayMain} src="images/google-play-download.png" alt='google play download' />
                            </Link>
                            <Link to={"https://apps.apple.com/br/app/athletic-gear-gestor/id1608434390"}>
                                <img className={styles.appStoreMain} src="images/app-store-download.png" alt='app store download' />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.addMainContainer02}>
                <div className={styles.addSubContainer02}>
                    <div className={styles.add02ImageContainer}>
                        <img className={styles.add02Image} alt='celular' src='images/add-02.png' />
                    </div>
                    <div>
                        <div className={styles.add02Title}>
                            <h4>Acesse em qualquer lugar</h4>
                            <h5>Seja você um treinador, gerente ou entusiasta, o SportsTeam Manager é a solução completa para todas as suas necessidades de gerenciamento de times de esportes. Tenha o controle total sobre o desempenho do seu time</h5>
                            <div className={styles.add02DownloadArea}>
                                <div className={styles.add02DownloadContainer}>
                                    <p className={styles.add02Download}>Baixe agora</p>
                                </div>
                            </div>
                            <div className={styles.add02DownloadIconContainer}>
                                <Link to={"https://play.google.com/store/apps/details?id=com.agsports.agsports_manager"}>
                                    <img className={styles.googlePlayMain} src="images/google-play-black.png" alt='google play download' />
                                </Link>
                                <Link to={"https://apps.apple.com/br/app/athletic-gear-gestor/id1608434390"}>
                                    <img className={styles.appStoreMain} id='depoimentos' src="images/app-store-black.png" alt='app store download' />
                                </Link>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.carouselContainer}>
                <div className={styles.carouselTitle}>
                    <h4>O que nossos clientes dizem</h4>
                    {width > 628 &&
                        <div className={styles.purpleButtonContainer}>
                            <button className={styles.purpleButtonLeft} onClick={cardsCarouselClickLeft} alt='botão da esquerda' />
                            <button className={styles.purpleButtonRight} onClick={cardsCarouselClickRight} alt='botão da direita' />
                        </div>
                    }
                </div>
                <img className={styles.carouselStar} src='images/green-star.png' alt='estrela' />
                <div className={styles.carouselContent} ref={cardsCarousel}>
                    {cards.map((card) => {
                        return (
                            <div className={styles.carousel} key={card.id}>
                                <div className={styles.carouselLeft}>
                                    <h3>{card.text}</h3>
                                    <div className={styles.carouselLeftAuthor}>
                                        <img src={card.icon} alt='imagem do autor' />
                                        <div>
                                            <h4>{card.author}</h4>
                                            <h5>{card.subtitle}</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className={styles.partnersArea} >
                <div className={styles.carouselTitle}>
                    <h4>Times que usam nossa ferramenta</h4>
                    {width > 628 &&
                        <div className={styles.purpleButtonContainer}>
                            <button className={styles.purpleButtonLeft} onClick={partnersCarouselClickLeft} alt='botão da esquerda' />
                            <button className={styles.purpleButtonRight} onClick={partnersCarouselClickRight} alt='botão da direita' />
                        </div>
                    }
                </div>

                <div className={styles.partnersContainer} ref={partnersCarousel}>
                    {partners.map((partner) => {
                        return (
                            <div className={styles.partner} key={partner.id}>
                                <img src={partner.icon} alt='parceiro'></img>
                                <h4>{partner.name}</h4>
                                <h5 id='fale_conosco'>{partner.type}</h5>
                            </div>
                        )
                    })}

                </div>
            </div>
            {displaySucess === true &&
                <div className={styles.sucessMsg}>
                    <h3>Dados enviados com sucesso!</h3>
                </div>
            }
            {displayFail === true &&
                <div className={styles.failMsg}>
                    <h3>Preencha todos os campos!</h3>
                </div>
            }
            <div className={styles.formContainer}>
                <div className={styles.formTitle}>
                    <h4>Fale conosco</h4>
                    <h5>Tenha um atendimento personalizado para suas necessidades como gestor ou atleta</h5>
                </div>

                <div className={styles.formArea}>
                    <input className={styles.formAreaOne} name='name' onChange={handleChangeValues} placeholder='Nome' autoComplete="nome" />
                    <input onChange={handleChangeValues} name='team' placeholder='Time' autoComplete="team" />
                    <input onChange={handleChangeValues} name='role' placeholder='Seu papel no time' autoComplete="role" />
                    <input onChange={handleChangeValues} name='email' placeholder='Email' autoComplete="email"/>
                    <InputMask onChange={handleChangeValues} mask="(99)99999-9999" name='phone' placeholder='Telefone' autoComplete="phone"/>
                    <textarea onChange={handleChangeValues} name='message' placeholder='Mensagem' autoComplete="msg" />
                    <div className={styles.formButtonContainer}>
                        <button onClick={handleOnClick}>Enviar</button>
                    </div>
                </div>
                
            </div>
            <Footer heightFooter={'26.75rem'} link='#pagina_inicial'/>
        </div>

    )
}

export default Home