import { Link } from 'react-router-dom'
import styles from './Footer.module.css'
import windowSize from '../elements/WindowsWatch'
import { useEffect } from 'react'
import leftBlur from '../images/leftBlurFooter.png'
import rightBlur from '../images/rightBlurFooter.png'
import athleticLogo from '../images/athletic-gear-logo.png'

function Footer({heightFooter, link='/', position = 'absolute'}) {



    const { width } = windowSize();

    useEffect(() => {
        if (width > 628) {
            //setFooterImg(footerBackground)


        }
        else {
            //setFooterImg(footerBackgroundMobile)

        }
    }, [width])


    return (
    <div className={styles.mainDiv} style={{position : position, height : heightFooter}}>
        <div className={styles.footer}>
            <a href={link}><img src={athleticLogo} style={heightFooter === '26.75rem' ? {marginTop : '229px'} : {}}  alt='logo athletic' /></a>
        </div>
        <div className={styles.footerBackgroundContainer}>

        <div className={styles.backgroundSocial}>
            <Link to={"https://www.instagram.com/athleticgearapp/"} className={styles.instagramIcon}></Link>
            <Link to={"https://wa.me/5531996734385"} className={styles.whatsappIcon}></Link>
            <Link to={"https://www.linkedin.com/company/athleticgear/"} className={styles.linkedinIcon}></Link>
        </div>
        <div className={styles.policyContainer}>
            <Link className={styles.policyLink} to={'/termos-de-uso'}>Termos de uso</Link>
            <Link className={styles.policyLink} to={'/politica-de-privacidade'}>Política de privacidade</Link>
        </div>
        </div>

        {width > 830 && <div className={styles.leftBlur}><img src={leftBlur} alt='blur'></img></div>}
        {width > 830 && heightFooter === '26.75rem' &&  <div className={styles.rightBlurHome}><img src={rightBlur} alt='blur'></img></div>}
        {width > 830 && heightFooter === '15.813rem' &&  <div className={styles.rightBlur}><img src={rightBlur} alt='blur'></img></div>}
       
    </div>
    )
}

export default Footer