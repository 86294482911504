import { Link } from "react-router-dom"
import styles from './Terms.module.css'
import { useEffect, useState } from "react";
import Footer from "../elements/Footer";
import NavBar from "../elements/NavBar";
import getGlobalVariable from "../elements/MainVariables"
import Loader from "../elements/Loader";

function Terms() {

    const [html, setHTML] = useState({ __html: "" });
    const [loader, setLoader] = useState(true)

    useEffect(() => {
        async function getData() {
            let response = await fetch(
                `${getGlobalVariable().mainUrl}/web/use_terms`)
            const dataToString = await response.text()
            setLoader(false)

            return { __html: dataToString };

        }
        getData().then(result => setHTML(result));
    }, [loader]);

    const scrollBack = () =>{
        window.scrollTo(0, 0)
    }



    return (
        <div>
            {loader
                ?
                <Loader />
                :
                <div className={styles.mainDiv} >
                    <NavBar showFaleconosco={false} />
                    <Link className={styles.titleDiv} id='link00' onClick={scrollBack} to={'/'}><img src="images/left-white-arrow.png" alt='retornar' /> <h4 className={styles.titleText}>Voltar para o inicio</h4></Link>

                    <div className={styles.subtitleDiv}>
                        <h1>Termos de uso</h1>
                    </div>
                    <div className={styles.termsContent} dangerouslySetInnerHTML={html}>

                    </div>
                    <Footer heightFooter={'15.813rem'}/>
                </div>
            }
        </div>
    )
}

export default Terms