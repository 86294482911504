import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from "./pages/Home"
import Terms from './pages/Terms';
import PolicyPrivacy from './pages/PolicyPrivacy';
import Pagar from './pages/Pagar';
import NotFound from './pages/NotFound';
import ExcluirConta from './pages/ExcluirConta';
import ExcluirContaTwo from './pages/ExcluirContaTwo';


function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/termos-de-uso' element={<Terms />} />
        <Route path='/politica-de-privacidade' element={<PolicyPrivacy />} />
        <Route path='/excluir-conta' element={<ExcluirConta />} />
        <Route path='/excluir-conta/:id' element={<ExcluirContaTwo/>} />
        <Route path='/pagar/:id' element={<Pagar />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>

  );
}

export default App;
